import React from 'react'
import { Button } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const ButtonField = ({type, name, value, fieldClass, step,handlechange,handlechange2, videotrue}) => (
	<div className={`btns ${step}  form-group`}>
		{step == 'stepone' &&
			<Button onClick={handlechange}  variant="" type={type} className={fieldClass}>
			{name}
			</Button>
		}
		{step == 'steptwo' &&
			<Button onClick={handlechange2}  variant="" type={type} className={fieldClass}>
			{name}
			</Button>
		}
		{step != 'stepone' && step != 'steptwo' &&
		<Button disabled={videotrue} variant="primary" type={type} className={fieldClass}>
			{name}
		</Button>
		
		}
	</div>
);

export default ButtonField;