import React, {useRef, useState, useEffect} from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import DatePicker from "react-datepicker";
import { numberWithCommas } from "@Components/common/site/common-functions" 
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";


const InputField = ({ startDate, setStartDate, fieldClass, labelClass, min, max, type, name, value, required, placeholder, handlechange, pattern, label, grpmd,step, defaultValue }) => {
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef();
  useEffect(() => {

    $("input[name=telephone]").keypress(function (e) {
      //if the letter is not digit then display error and don't type anything
      if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
         //display error message
         $("#errmsg").html("Digits Only").show().fadeOut("slow");
                return false;
     }
    });
  });

  const toggleEditing = () => {
    setEditing(!isEditing);
    if (isEditing) {
      inputRef.current.focus();
    }
  };
  return (
  <Form.Group md={grpmd} className={`${type} ${step}`} controlId={"validation" + name}>
      <div className="custom-float">
      {label
        ? <Form.Label className="form-label">{label}{required ? '*' : ''}</Form.Label>
        : ''
      }

      {type === 'datetime' ?
        // <input
        // ref={inputRef}
        // onClick={toggleEditing}
        // className={fieldClass}
        //   required={required}
        //   type={type}
        //   name={name}
        //   value={value}
        //   placeholder={placeholder}
        //   onChange={handlechange}
        //   pattern={pattern}
        //   min={min}
        //   max={max}
        //   onkeydown="return false"
        // />
        <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                showTimeSelect
                minDate={new Date()}
                placeholderText={placeholder}
                dateFormat="MMMM d, yyyy h:mm aa"
                className={fieldClass}
                required={required}
                autocomplete="off"
                name={name}
                autoComplete={'' + Math.random()}                
                // onChange={handlechange}
                value={startDate}
                
              />
        :
        <Form.Control
          className={fieldClass}
          required={required}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handlechange}
          // pattern={pattern}
          autocomplete="off"
          defaultValue={defaultValue || ``}
        />
      }
      {placeholder?
          <label className="plclabel">{placeholder}</label>
          :""
      }
    </div>
  </Form.Group>
 );
};
export default InputField;